import * as React from "react"
import ContactInfo from "../components/contact-info"
import Page from "../components/page"
import { contactInfo } from "./contact-us.module.css"

export default function ContactUs() {
  return (
    <Page seoProps={{ title: "contact us" }}>
      <ContactInfo className={contactInfo} />
    </Page>
  )
}
