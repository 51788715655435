import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import {
  contactListItem,
  contactLabel,
  contactLink,
  contactInfo,
  contactHeading,
  contactWrapper,
  contactList,
} from "./contact-info.module.css"
import getClass from "../utils/get-class"

function ContactItem({ children, label }) {
  return (
    <li className={contactListItem}>
      <span className={contactLabel}>{label}</span>
      {children}
    </li>
  )
}

function ContactLinkItem({ children, href, label }) {
  return (
    <ContactItem label={label}>
      <OutboundLink
        className={contactLink}
        href={href}
        target="_blank"
        rel="noopener"
      >
        {children}
      </OutboundLink>
    </ContactItem>
  )
}

function ContactInfo({ className }) {
  const {
    site: {
      siteMetadata: { email, twitter },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            email
            twitter
          }
        }
      }
    `
  )

  return (
    <section
      aria-label="contact info"
      className={getClass(className, contactInfo)}
    >
      <h2 className={contactHeading}>get in touch</h2>
      <div className={contactWrapper}>
        <ul className={contactList}>
          <ContactLinkItem href={`mailto:${email}`} label="email">
            {email}
          </ContactLinkItem>
          <ContactLinkItem
            href={`https://twitter.com/${twitter}`}
            label="twitter"
          >
            @{twitter}
          </ContactLinkItem>
        </ul>
      </div>
    </section>
  )
}

ContactInfo.defaultProps = {}

ContactInfo.propTypes = {
  className: PropTypes.string,
}

export default ContactInfo
